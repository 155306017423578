@import "~antd/dist/antd.dark.less";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
//@import '~antd/dist/antd.compact.less';

body {
  font-family: "Montserrat";
}

.ant-layout-header {
  height: auto !important;
  border-bottom: 1px solid #3a3a3a;
}

.ant-menu-dark.ant-menu-horizontal,
.ant-menu-horizontal {
  background-color: transparent !important;
}

.ant-menu-dark .ant-menu-item-selected {
  color: #02225c;
}

tr.ant-table-expanded-row .ant-descriptions-view table {
  width: 100% !important;
}

@body-background: #313131;
@primary-color: #f0cb65;
@text-color: #fff;
@text-color-secondary: #fff;
@btn-primary-color: #02225c;

